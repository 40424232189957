import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export function getProgressPercentage (rungs) {
	if (!rungs) {
		return 0;
	}

	const totalRungs = rungs.length;
	const numCompleted = rungs.reduce(function(n, rung) {
		return n + (rung.status === "completed" || rung.status === "pending" || rung.status === "paid");
	}, 0);

	if (!totalRungs) {
		return 0;
	}

	return parseFloat((numCompleted / totalRungs * 100).toFixed(0));
}

class RungHeading extends Component {

	render () {
		let heading = (
			<p>Simply complete one or more tasks from each Level</p>
		);

		if (this.props.showBack) {
			heading = (
				<p className={"back-link"}>
					<Link to={"/tasks/" + this.props.rung.url_slug}>
						&lt; back to tasks
					</Link>
				</p>
			);
		}

		return (
			<div className="rung-heading">
				{this.props.showH1 === false ? <></> : <h1
					className="hide">{"Level " + this.props.rung?.ladder_position + " Tasks"}</h1>}
				<h2 className="step">
					Level <div className={"circle " + this.props.rung?.status}>{this.props.rung?.ladder_position}</div>
				</h2>
				{heading}
			</div>
		);
	}
}

RungHeading.propTypes = {
	rung: PropTypes.object.isRequired,
	showBack: PropTypes.bool,
	showH1: PropTypes.bool
};

export default RungHeading;