import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../utilities/tracking";
import HelpdeskLoader from "./HelpdeskLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

class Footer extends Component {
	render () {
		const loggedIn = getToken() !== null;

		const loggedInLinks = (
			<>
				<li><HelpdeskLoader /></li>
				<li><Link to="/faqs" accessKey="f">FAQs</Link></li>
				<li><Link to="/about-us">About Us</Link></li>
				<li><Link to="/contact-us">Contact Us</Link></li>
				<li><Link to="/privacy-notice" accessKey="p">Privacy Notice</Link></li>
				<li><Link to="/terms-and-conditions" accessKey="t">Terms & Conditions</Link></li>
				<li><Link to="/affiliates">Affiliates</Link></li>
				<li><Link to="/earnings-disclaimer">Earnings Disclaimer</Link></li>
			</>
		);

		const loggedOutLinks = (
			<>
				<li className='footer-signup'>
					<a href="/signup"
					   accessKey="s"
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.showModal("signup","cta6");
					   }}
					>Sign Up</a>
				</li>
				<li className='footer-login'>
					<a href="/login"
					   accessKey="l"
					   onClick={(event) => {
						   event.preventDefault();
						   this.props.showModal("login");
					   }}>Login</a>
				</li>
				<li><HelpdeskLoader /></li>
				<li><Link to="/faqs" accessKey="f">FAQs</Link></li>
				<li><Link to="/about-us">About Us</Link></li>
				<li><Link to="/contact-us">Contact Us</Link></li>
				<li><Link to="/privacy-notice" accessKey="p">Privacy Notice</Link></li>
				<li><Link to="/terms-and-conditions" accessKey="t">Terms & Conditions</Link></li>
				<li><Link to="/affiliates">Affiliates</Link></li>
				<li><Link to="/earnings-disclaimer">Earnings Disclaimer</Link></li>
			</>
		);

		return (
			<footer className={'footer ' + (this.props.className ?? "")}>
				<div className="footer-logo" />
				<ul className="footer-links">
					{loggedIn ? loggedInLinks : loggedOutLinks}
				</ul>
				<div className='footer-social'>
					<a href='https://www.facebook.com/www.Cashback.co.uk?locale=en_GB'><FontAwesomeIcon
					className='footer-social-icon'
						icon={faFacebookSquare} /></a>
				</div>
				<p className="footer-copyright">Copyright
					&copy;{new Date().getFullYear()} Cashback<br />
					Owned by Submission Technology Ltd (04456811)<br />
					Submission Technology LTD, Turkey Mill, Ashford Road, Maidstone, Kent ME14 5PP</p>
			</footer>
		);
	}
}

export default Footer;